// @import "primeng/resources/themes/md-light-indigo/theme.css";
:root {

    --body-backgound: #F8F9FA;

    --surface-ground:#ffffff;
    --text-color: #212633;
    --main-background: #292663;
    --main-background-hover: #201d50;
    --color-with-background: #ffffff;
    --main-color: #292663;
    --main-color-1c12ca: #1c12ca;
    --white-background: #ffffff;
    --orange-background: #FF7C59;

    --header-background: #0C3399;

    --white-color: #ffffff;
    --white-color-06: rgba(255, 255, 255, 0.6);

    --ink-color:#505A71;
    --ink-color-5: #ECEFF8;
    --ink-color-6: #808AA6;
    --ink-color-20: #CAD2E6;
    --ink-color-40: #A9B3CC;
    --ink-color-80: #505A71;
    --ink-color-00: #F7FAFF;
    --p-menubar-500: 500;

    --bg-grey-02: rgba(255, 255, 255, 0.2);
    --grey-color: #80808078;

    --black-box-shadow-005: 0px 2px 2px rgb(0 0 0 / 5%);
    --black-box-shadow-01: 0px 4px 10px rgb(0 0 0 / 10%);
    --black-filter-shadow-005: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.05));
    --box-shadow-005: 0px 2px 2px rgba(0, 0, 0, 0.05);
    --border-grey: #D9DFF0;

    --bg-F3F9FF: #F3F9FF;
    --eaebed: #eaebed;
    --f1f1f1: #f1f1f1;
    --FFEFEB: #FFEFEB;
    --bdbdbd: #bdbdbd;

    --black-08: rgba(43, 43, 51, 0.8);
    --00CB51: #00CB51;
    --e5e5e5: #e5e5e5;
    --eff3ff: #eff3ff;
    --8a9cd3: #8a9cd3;
    --515663: #515663;
    --000000: #000000;
    --F3F9FF: #F3F9FF;
    --292663: #292663;
    --red: #ff0000;
    --B8C3E1: #B8C3E1;
    --E2E9FC: #E2E9FC;
    --D6DFF6: #D6DFF6;
    --2B2B33: #2B2B33;
    --c10000: #c10000;
    
}

@import '../node_modules/primeng/resources/themes/md-light-indigo/theme.css';


body{
    background-color: var(--body-backgound);
}


/* color */
.white-color{
    color: var(--white-color)
}
.white-color-06{
    color: var(--white-color-06)
}


.p-toolbar{
    background-color: var(--header-background);
}

/* #p-menubar */
.p-menubar {
    .p-menubar-root-list{
        & > .p-menuitem {
            &> .p-menuitem-link{
                &:not(.p-disabled):hover {
                    .p-menuitem-text{
                        color: var(--main-color);
                    }
                    background: none;
                    .p-submenu-icon,
                    .p-menuitem-icon{
                        color: var(--main-color);
                    }
                    border-bottom-color: var(--main-color);
                }
            }
            &>.p-menuitem-link{
                .p-menuitem-text{
                    color: var(--515663)
                }
                .p-submenu-icon{
                    color: var(--ink-color-40);
                }
            }
            &.parent_active{
                &>a{
                    &>.p-menuitem-text{
                        color: var(--main-color-1c12ca);
                    }
                }
                .p-menuitem-icon{
                    color: var(--main-color);
                }
                .p-submenu-icon{
                    color: var(--main-color);
                }
                .p-submenu-list{
                    &>li.active{
                        &>a{
                            background: var(--main-background);
                            .p-menuitem-text{
                                color: var(--white-color);
                            }
                        }
                    }
                }
            }
            .p-submenu-list{
                &>li{
                    &>a{
                        .p-menuitem-text{
                            color: var(--000000);
                        }
                    }
                }
            }
        }
        .p-submenu-list{
            background: var(--white-background);
            box-shadow: var(--black-box-shadow-01);
            li{
                a{
                    &:focus,
                    &:hover{
                        background: var(--main-background);
                        span{
                            color: var(--white-color) !important;
                        }
                    }
                }
                &>.p-menuitem-active{
                    a{
                        background: var(--main-background);
                        span{
                            color: var(--white-color) !important;
                        }
                    }  
                }
            }
            &>li{
                &>a{
                    color: var(--text-color);
                }
            }
        }
        &>.p-menuitem-active{
            &>.p-menuitem-link{
                span{
                    color: var(--main-color) !important;
                }
                border-bottom-color: var(--main-color);
            }
        }
    }
    .p-menuitem-link{
        .p-menuitem-text{
            color: var(--ink-color);
            font-weight: var(--p-menubar-500);
        }
    }
    background: var(--white-background);
    box-shadow: var(--black-box-shadow-005);
}

/* #p-tree */
.p-tree{
    box-shadow: var(--black-box-shadow-005);
    .p-tree-container {
        .p-treenode {
            .p-treenode-content{
                color: var(--ink-color);
                .p-tree-toggler-icon{
                    color: var(--ink-color);
                }
                &:hover{
                    background: var(--main-background) !important;
                    .p-tree-toggler-icon{
                        color: var(--white-color);
                    }
                    color: var(--white-color) !important;
                    .p-treenode-icon{
                        color: var(--white-color);
                    }
                }
            }
        }
    }
    .p-tree-wrapper{
        border: 1px solid var(--border-grey);
    }
}
/*#p-datatable-header */
.p-datatable-gridlines{
    .p-datatable-header{
        background: var(--white-background);
        box-shadow: var(--black-box-shadow-005);
        .p-multiselect{
            border: 1px solid var(--border-grey);
            .p-multiselect-label{
                color: var(--ink-color-6);
            }
        }
    }
}
.p-datatable {
    .p-paginator-bottom {
        .p-paginator-rpp-options{
            background: var(--main-background);
            .p-dropdown-label{
                color: var(--white-color);
            }
            .p-dropdown-trigger{
                color: var(--white-color);
            }
        }
        .p-paginator-pages{
            .p-paginator-page{
                &:hover{
                    background: var(--ink-color-5);
                }
                &.p-highlight{
                    background: var(--main-background);
                    color: var(--white-color);
                }
            }
        }
    }
}

/*#paginator */
.paginator{
    box-shadow: var(--black-box-shadow-005);
    .p-paginator-rpp-options{
        background: var(--main-background);
        .p-dropdown-label{
            color: var(--white-color);
        }
        .p-dropdown-trigger{
            color: var(--white-color);
        }
    }
    .p-paginator-pages{
        .p-paginator-element{
            &.p-highlight{
                background: var(--main-background);
                color: var(--white-color);
            }
        }
    }
}

/*#grid-default*/
.grid-default{
    box-shadow: var(--black-filter-shadow-005);
    background: var(--white-background);
    .ag-header{
        border-bottom: none;
        .ag-header-cell-text{
            color: var(--ink-color);
        }
        background-color: var(--white-background) !important;
    }
    .ag-header-viewport{
        filter: var(--black-filter-shadow-005);
    }
    .ag-root-wrapper{
        .ag-header-row{
         .ag-header-cell{
            background: var(--white-background);
            span{
                color: var(--text-color);
                font-size: 14px;
            }
         }   
        }
    }

    .ag-body-viewport{
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px var(--grey-color); 
        }
        &::-webkit-scrollbar-thumb {
            background: var(--main-background); 
        }
        &::-webkit-scrollbar-thumb:hover {
            background: var(--header-background); 
        }
    }
    .ag-body-horizontal-scroll-viewport{
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 2px var(--grey-color); 
        }
        &::-webkit-scrollbar-thumb {
            background: var(--main-background); 
        }
        &::-webkit-scrollbar-thumb:hover {
            background: var(--header-background); 
        }
    }
}
/*#scroll*/
.ag-virtual-list-viewport,
.p-dropdown-items-wrapper{
    &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px var(--grey-color); 
    }
    &::-webkit-scrollbar-thumb {
        background: var(--main-background); 
    }
    &::-webkit-scrollbar-thumb:hover {
        background: var(--header-background); 
    }
}

/* #p-datatable  */
// .p-datatable {
//     .p-datatable-tbody{
//         &> tr.p-selectable-row {
//             &>td{
//                 border-bottom: 1px solid var(--border-grey) !important;
//             }
//         }
//     }
// }
/* #p-datatable  */
.p-datatable {
    .p-datatable-thead {
        &> tr {
            filter: var(--black-filter-shadow-005);
            &> th{
                color: var(--ink-color-80)
            }
        }
    }
}
/* #p-paginator-bottom */
.p-datatable{
    .p-paginator-bottom{
        .p-paginator-rpp-options{
            .p-paginator-rpp-options{
            }
        }
    }
}

/* #breadcrumb */
.breadcrumb{
    .p-breadcrumb{
        &>ul{
            &>li{
                &:last-child{
                    a{
                        span{
                            color: var(--main-color);
                        }
                    }
                }
                &>a{
                    &.p-menuitem-link-active{
                        &:hover{
                            span{
                                color: var(--main-color);
                            }
                        }
                    }
                }
            }
        }
    }
}

/* default */
.btn{
    color: var(--white-color);
    background: var(--main-background);
    &.btn-default{
        &:hover{
            background: var(--main-background-hover);
        }
    }
    &.btn-orange{
        background: var(--orange-background);
        &:hover{
            background: var(--main-background-hover);
        }
    }
}
    .p-selectbutton {
        .p-button{
            color: var(--white-color);
            background: var(--main-background);   
            &:hover{
                background: var(--main-background-hover) !important;
                color: var(--white-color) !important;
            }   
            // &.p-highlight{
            //     background: var(--main-background-hover) !important;
            //     color: var(--white-color) !important;
            // }
        }
    }

.input-group{
    .p-calendar-w-btn,
    input{
        color: var(--ink-color-6);
        border: 1px solid var(--border-grey);
        &:focus-visible{
            border: 1px solid var(--border-grey);
        }
        &:hover{
            border: 1px solid var(--border-grey);
            border-color: var(--border-grey) !important;
        }
        &:focus{
            border-color: var(--border-grey) !important;
        }
    }
    .p-calendar2{
        .p-button-icon{
            color: var(--main-color) !important;
        }
    }
    .alert-validation{
        color: var(--red);
    }
}

.img-theme .p-avatar{
    &.p-avatar-image{
        background: var(--bg-grey-02);
    }
}
.img-theme{
    &.icon{
        background: var(--bg-grey-02);
    }
}

.p-button{
    background: var(--main-background)
}
.p-button:enabled:hover{
    background: var(--main-background-hover);
}

.header-filter-search{
    .input-group{
        .pi-angle-down{
            color: var(--text-color);
            &:hover{
                color: var(--white-color);
                background: var(--main-background);
            }
        }
    }
}

.p-button-warning{
    .p-button{
        background: var(--orange-background) !important;
        color: var(--white-color) !important;
    }
}
.input-group input{
    &::-webkit-input-placeholder { /* Edge */
        color: var(--ink-color-6);
      }
      
      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: var(--ink-color-6);
      }
      
      &::placeholder {
        color: var(--ink-color-6);
      }
}

.p-tree-filter-container{
    input{
        color: var(--ink-color-6);
        border: 1px solid var(--border-grey);
    }
    .p-tree-filter-icon{
        color: var(--ink-color-6) !important;
    }
}

.p-menu-list{
    li{
        a{
          span{
            color: var(--515663) !important;
          }
          &:hover{
            background: none !important;
            span{
                color: var(--main-color) !important;
            }
          }
        }
        &.active{
            a{
                span{
                    color: var(--000000) !important;
                }
            }
        }
    }
}

.white-background{
    background: var(--white-background);
}

.white-background{
    background: var(--white-background);
}
.black-box-shadow-005{
    box-shadow: var(--black-box-shadow-005);
}
/* #select-default */
.select-default{
    label{
        color: var(--text-color);
    }
    .alert-danger{
        color: var(--red)
    }
    .p-multiselect,
    .p-dropdown{
        border: 1px solid var(--border-grey);
        span{
            color: var(--ink-color-40);
        }
        &:hover{
            border: 1px solid var(--main-background);
        }
    }
    .p-dropdown-trigger{
        .p-dropdown-trigger-icon{
            color: var(--main-color);
        }
    }
    .p-multiselect{
        .p-multiselect-label{
            color: var(--B8C3E1);
        }
        .p-multiselect-panel{
            .p-multiselect-header{
                border-color: var(--D6DFF6);
            }
            .p-checkbox{
                border-color: var(--D6DFF6);
                .p-checkbox-box{
                    
                }
            }
            .p-multiselect-filter-container{
                .p-multiselect-filter{
                    border-color: var(--E2E9FC);
                }
            }
        }
    }
    .p-multiselect-items{
        li{
            span{
                color: var(--2B2B33);
            }
        }
    }
}
.box-15{
    background: var(--white-background);
    box-shadow: var(--black-box-shadow-005);
}
.reload-filter{
    background: var(--bg-F3F9FF);
    &:hover{
        background: var(--main-background);
        svg{
            path{
                fill: var(--white-color)
            }
        }
    }
}

.product-detail{
    .content{
        background: var(--white-background);
        box-shadow: var(--black-box-shadow-005);
        position: relative;
        .p-selectbutton{
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }
}
.p-selectbutton{
    &.two-buttons{
        .p-button{
            &:first-child{
                background: var(--bg-F3F9FF);
                color: var(--main-color);
                &:hover{
                    background: var(--border-grey) !important;
                    color: var(--main-color) !important;
                }
            }
        }
    }
}
.panel-heading{
    .panel-title{
       color: var(--text-color);
    }
}
.checkbox-default{
    .p-checkbox-label{
        color: var(--text-color);
    }
    .p-checkbox{
        .p-checkbox-box{
            border-color: var(--ink-color-20) !important;
            // background: var(--ink-color-20);
            &.p-highlight{
                background: var(--main-background);
                border-color: var(--main-background) !important;
            }
        }
    }
}

.slider-default{
    .slider-in{
        .p-slider-range,
        .p-slider-handle{
            background: var(--main-background) !important;
        }
    }
}
.grid-default{
    .ag-body-viewport{
        .ag-row-level-1{
            background: var(--f1f1f1);
            .ag-details-row{
                background: var(--f1f1f1);
                .ag-header-row{
                    .ag-header-cell{
                        background: var(--f1f1f1);
                    }
                }
                .ag-root-wrapper{
                    background: var(--f1f1f1);
                }
            }
            .ag-row{
                .ag-cell{
                    background: var(--f1f1f1);
                    border-top: 1px solid var(--eaebed);
                }
            }
        }
    }
}

.menu-timeline{
    .p-tabmenu-nav{
        background: none;
        li{
            a{
                span{
                    color: var(--ink-color-6);
                }
            }
            &.p-tabmenu-ink-bar{
                background-color: var(--main-background);
            }
            &.p-highlight{
                a{
                    span{
                        color: var(--main-color);
                    }
                }
            }
        }
    }
    .p-dropdown{
        background: var(--bg-F3F9FF);
        .p-dropdown-label{
            color: var(--main-color);
        }
        .p-dropdown-trigger{
            color: var(--main-color);
        }
    }
}

.home{
    .section{
        &.chart{
            background-color: var(--white-background);
            box-shadow: var(--box-shadow-005);
        }
        background: var(--white-background);
        box-shadow: var(--box-shadow-005);
        .readmore{
            color: var(--main-color);
        }
    }
    .boxs{
        .box{
            background: var(--white-background);
            box-shadow: var(--box-shadow-005);
            h5{
                color: var(--ink-color-6);
                font-weight: normal;
            }
            .quan{
                .percen{
                    background: var(--FFEFEB);
                    color: var(--orange-background);
                }
            }
        }
        .icon{
            background: var(--FFEFEB);
        }
    }
}
.home{
    .activites{
        .p-timeline-event{
            .p-timeline-event-content{
                span{
                    color: var(--main-color);
                }
            }
        }
    }
}
.timeline-item{
    i{
        color: var(--ink-color-6);
    }
}

.timeline-default{
    .p-timeline-event-separator{
        .p-timeline-event-marker{
            border: 1px solid var(--orange-background);
        }
        .p-timeline-event-connector{
            background-color: var(--bdbdbd);
        }
    }
}
.home-notice{
    background: var(--black-08);
    color: var(--white-color);
    .contact{
        .btn{
            background: var(--00CB51);
        }
    }
}
.border-e5e5e5{
    border: 1px solid var(--e5e5e5);
}
.header{
    .p-toolbar{
        background: var(--292663);
        box-shadow: var(--box-shadow-005);
    }
}
.left-sidebar{
    .p-menu{
        background: var(--eff3ff);
        .p-menu-list{
            .p-submenu-header{
                background: transparent;
                span{
                    color: var(--8a9cd3);
                }
            }
        }
    }
}
.p-datepicker{
    &.datepicker-default{
        .p-datepicker-title{
            select{
                border: 1px solid var(--border-grey);
                color: var(--ink-color-6);
            }
        }
    }
}
.p-dialog-footer{
    .p-button{
        &.btn-cancel{
            background: var(--red);
            &:hover{
                background: var(--c10000);
            }
        }
    }
}
.p-button.p-button-info:enabled:hover, .p-buttonset.p-button-info > .p-button:enabled:hover, .p-splitbutton.p-button-info > .p-button:enabled:hover,
.p-fileupload-choose:not(.p-disabled):hover{
    background: var(--main-background-hover);
}
.p-button.p-button-info, .p-buttonset.p-button-info > .p-button, .p-splitbutton.p-button-info > .p-button{
    background: var(--main-background);
}

.p-steps{
    ul{
        li{
            &.active{
                .p-steps-number{
                    background: var(--main-background) !important;
                }
                &.p-highlight{
                    .p-steps-number{
                        background: var(--main-background) !important;
                    }
                }
            }
        }
    }
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar{
    background: var(--main-background) !important;
}
.sl-steps{
    &.items-3{
        ul{
            li{
                &.active{
                    &::before{
                        border-top: 2px solid var(--main-background);
                    }
                    &::after{
                        border-top: 2px solid var(--main-background);
                    }
                    &::before{
                        border-top: 2px solid var(--main-background);
                    }
                    &::after{
                        border-top: 2px solid var(--main-background);
                    }
                }
            }
        }
    }
}
.tur-money{
    .money{
        color: var(--main-background);
    }
    .symbol path {
        fill: var(--main-background);
    }
}

.table-tool{
    .p-selectbutton{
        .p-button{
            &.p-highlight,
            &:hover{
                border-color: var(--main-background) !important;
                position: relative;
                z-index: 2;
                background: var(--main-background);
            }
        }
    }
}